// rssgeeksforgeeks.js

import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import RSSGeeksForGeeksContent from 'components/RSSGeeksForGeeksContent'

function RSSGeeksForGeeksPage({ location }) {
	// Page-Title Override
	// const pageTitle = location ? location.pathname.replace(/\//g, '') : ''
	const pageTitle = "'Geeks4Geeks' RSS Feed"

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<RSSGeeksForGeeksContent />
		</Layout>
	)
}

RSSGeeksForGeeksPage.propTypes = {
	location: PropTypes.object,
}

export default RSSGeeksForGeeksPage
